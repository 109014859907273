<template>
  <v-dialog v-model="dialog" width="350">
    <template v-slot:activator="{ on }">
      <v-btn v-if="activator == 'btn'" v-on="on" icon>
        <v-icon size="36">mdi-plus-circle</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>{{ $tc("invoices.tax", 2) }}</v-card-title>
      <div class="close">
        <v-btn icon @click="dialog = false" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card-text>
        <v-row>
          <v-col class="pa-0" align="end">
            <NewTax @update="fetch" />
          </v-col>
        </v-row>
      </v-card-text>
      <base-data-table-cards
        :data="items"
        :headers="headers"
        :headers_small="headers_small"
        :options.sync="options"
        :server-item-length="total"
        :totalItems="total"
        :title="$tc('invoices.tax', 1)"
      >
        <template v-slot:[`item_percentage`]="item">
          {{ $n(item.percentage / 100, "percent") }}
        </template>
        <template v-slot:[`item_actions`]="item">
          <UpdateTax
            :tax_id="item.id"
            :ref="`update-${item.id}`"
            @update="fetch"
          />
          <v-menu>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on">mdi-dots-horizontal</v-icon>
            </template>
            <v-list>
              <v-list-item @click="$refs[`update-${item.id}`].open()">
                <v-icon class="mr-2" small style="margin-top: 0px !important">
                  $edit
                </v-icon>
                <span> {{ $t("edit", { name: $tc("invoices.tax", 1) }) }}</span>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </base-data-table-cards>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "TaxesDialog",
  props: {
    activator: {
      type: String,
      default: null,
    },
  },
  components: {
    NewTax: () => import("@/components/invoices/taxes/New"),
    UpdateTax: () => import("@/components/invoices/taxes/Update"),
  },
  data() {
    return {
      dialog: false,
      items: [],
      headers: [
        {
          text: "Name",
          align: "center",
          sortable: false,
          value: "name",
        },
        {
          text: "Porcentage",
          align: "center",
          sortable: false,
          value: "percentage",
        },

        {
          text: "Region",
          align: "center",
          sortable: false,
          value: "region",
        },
        {
          text: this.$t("actions"),
          align: "center",
          sortable: false,
          value: "actions",
        },
      ],
      headers_small: [
        { value: "name", class: "text-center" },
        { value: "percentage", class: "text-center" },
        { value: "region", class: "text-center" },
      ],
      options: {},
      total: null,
      filters: {},
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    ...mapActions("taxes", ["getTaxes"]),
    fetch() {
      this.getTaxes({
        filters: this.filters,
        pagination: this.options,
      }).then((response) => {
        this.items = response.data;
        this.total = response.total;
      });
    },
  },
  watch: {
    options: {
      handler() {
        this.fetch();
      },
      deep: false,
    },
  },
};
</script>
